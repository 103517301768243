.wrapper {
  background-color: transparent;
  margin: 24px 0 0 0;
  border-top: #f7f7f7 2px solid;
}

.container {
  background-color: transparent;
  margin: 24px 0 0 0;
  gap: 24px;
}

.content {
  padding: 0;
}

.toolbar {
  align-items: center;
  justify-content: space-between;
  flex: 1;
  background-color: #f7f7f7;
  border-radius: 20px;
  padding: 10px 20px;
  color: #676767;

  &Label {
    margin-right: 5px;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 200px;
  position: sticky;
  top: 24px;

  a {
    text-decoration: none;
    color: #999;

    &:hover {
      color: #676767;
    }

    &[aria-current] {
      color: #000;
      font-weight: 500;
    }
  }
}
