.map {
  width: 100%;
  height: 350px;
  border-radius: 12px;
  border: #f7f7f7 1px solid;
  z-index: 10;
}

.marker {
  background-color: #797edc;
  border: #fff 2px solid;
  border-radius: 50%;

  &.orange {
    background-color: orange;
  }

  &.yellow {
    background-color: yellow;
  }

  &.blue {
    background-color: blue;
  }

  &.cyan {
    background-color: cyan;
  }

  &.grey {
    background-color: grey;
  }

  &.violet {
    background-color: violet;
  }

  &.green {
    background-color: green;
  }
}

:global {
  .marker-cluster {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    color: #fff;
    font-size: 11px;
    font-weight: 600;
    padding: 5px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      border-radius: 50%;
      background-color: #fe8257;
    }
  }
}
