.tag {
  display: flex;
  align-items: center;
  padding: 10px 24px;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px 0 1px 0;
  margin: 0 -24px -1px -24px;
  cursor: pointer;

  &.selected {
    background: #fff;
  }
}

.content {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .tag {
    margin-left: -15px;
    margin-right: -15px;
  }
}
