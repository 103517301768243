.wrapper {
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.model {
  border: #f7f7f7 2px solid;
}

.map {
  height: 272px;
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inlineRow {
  gap: 20px;
}

.action {
  flex: 1;
  height: 86px;

  &Icon {
    display: block;
    margin: 0 auto 5px;
  }
}

.tabs {
  padding: 0 20px;
}
