.filter {
  position: absolute;
  width: 300px;
  z-index: 100;
  top: 35px;
  right: 35px;
  padding: 5px 24px;
}

.toggler {
  display: flex;
}

.content {
  border-top: #f7f7f7 1px solid;
}
