.link {
  color: #2e292b;
  display: inline-flex;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  color: #bbb;
  margin-bottom: 12px;

  svg {
    margin-right: 5px;
  }

  &:hover {
    color: #676767;
  }
}
