:global {
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    .react-datepicker__triangle {
      &:before,
      &:after {
        left: 12px;
      }

      &:before {
        border-bottom-color: #f8f8f9;
      }

      &:after {
        border-bottom-color: #fff;
      }
    }
  }

  .react-datepicker {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 10px;

    .react-datepicker__header {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding-top: 14px;
      background: #fff;
      border: none;
    }

    .react-datepicker__current-month {
      &.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
        display: none;
      }
    }

    .react-datepicker__navigation {
      width: 25px;
      height: 25px;
      border: none;
      border-radius: 5px;
      color: transparent;
      top: 11px;

      &:before {
        display: inline-block;
        content: '';
        border: solid #000;
        border-width: 0 2px 2px 0;
        padding: 3px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -5px;
      }

      &.react-datepicker__navigation--previous {
        &:before {
          margin-left: -3px;
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
        }
      }

      &.react-datepicker__navigation--next {
        &:before {
          margin-left: -5px;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }
      }

      &.react-datepicker__navigation--years-upcoming {
        top: 2px;

        &:before {
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
        }
      }

      &.react-datepicker__navigation--years-previous {
        top: -1px;

        &:before {
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
        }
      }
    }

    .react-datepicker__header__dropdown {
      margin: 0 0 10px 0;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 11px;

      .react-datepicker__month-read-view--down-arrow,
      .react-datepicker__year-read-view--down-arrow {
        top: 3px;
        width: 6px;
        height: 6px;
        border-width: 2px 2px 0 0;
        border-radius: 1px;
        border-color: #000;
      }

      .react-datepicker__month-dropdown,
      .react-datepicker__year-dropdown {
        top: 15px;
        background: #fff;
        border: none;
        box-shadow: 0px 0 25px -14px #000;
        // text-align: left;
      }

      .react-datepicker__month-option,
      .react-datepicker__year-option {
        padding: 5px 10px;
        font-size: 12px;
        font-weight: 500;
        text-transform: capitalize;
        border-bottom: #e5e6e8 1px solid;

        &:hover {
          background-color: #fbfbfb;
        }

        &.react-datepicker__month-option--selected_month,
        &.react-datepicker__year-option--selected_year {
          background-color: #57b7d9;
          color: #fff;
        }

        .react-datepicker__month-option--selected,
        .react-datepicker__year-option--selected {
          display: none;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    .react-datepicker__day-names {
      border-bottom: #e5e6e8 1px solid;
    }

    .react-datepicker__day-name,
    .react-datepicker__day {
      width: 35px;
      height: 30px;
      line-height: 30px;
    }

    .react-datepicker__day-name {
      text-transform: uppercase;
      font-size: 9px;
      font-weight: 600;
      color: #868997;
    }

    .react-datepicker__day {
      font-size: 12px;

      &.react-datepicker__day--keyboard-selected {
        background-color: #57b7d9;
        font-weight: 600;
      }
    }
  }
}

.wrapper {
  position: relative;
}

.input {
  background: #fff url('./calendar.svg') calc(100% - 10px) center no-repeat;
  background-size: 16px;
  min-height: 36px;

  &::placeholder {
    color: #868a97;
  }
}
