.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  align-items: center;
  justify-content: center;

  background-image: url(~assets/ufo.jpg);
  background-size: contain;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 130px;
  font-weight: 600;
}
