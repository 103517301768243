.row {
  display: flex;
}

.key {
  font-size: 12px;
  font-weight: 500;
  width: 35%;
  padding: 10px 15px 10px 0;
}

.value {
  flex: 1;
  box-sizing: border-box;
  font-size: 12px;
  margin-bottom: 2px;
  margin-top: 2px;
  overflow: visible;
  padding: 8px 10px;
  white-space: pre-wrap;
  word-break: break-word;
  background-color: #f7f7f7;
}
