.wrapper {
  position: relative;
  height: calc(100vh - 60px);
  margin: -30px -35px -100px -35px;

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 200;
    box-shadow: 0px 5px 8px -7px rgba(0, 0, 0, 0.35) inset;
    height: 10px;
  }
}

.map {
  border: none;
  border-radius: 0;
  width: auto;
  height: 100%;
}
