.table {
  border-spacing: 0;
  margin-bottom: 20px;

  thead {
    td {
      background-color: #f7f7f7;
      color: #000;
    }
  }
}
