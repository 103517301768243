.link {
  color: #2e292b;
  display: inline-flex;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.icon {
  margin: 6px 8px 0 0;
}
