@use 'sass:math';

:global {
  * {
    box-sizing: border-box;

    outline: none;

    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  html,
  body {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    line-height: math.div(20, 14);

    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #fff;
  }

  html,
  body {
    height: 100%;
    min-height: 100%;
    position: relative;
  }

  pre {
    display: block;
    word-wrap: break-word;
    padding: 9.5px;
    word-break: break-all;
    border-radius: 4px;
  }

  button {
    font-family: 'Rubik', sans-serif;
  }

  .Toastify__toast {
    font-size: 12px;
    font-weight: 500;
    border-radius: 16px;
  }

  .Toastify__progress-bar {
    height: 2px;
  }
}

:root {
  --toastify-toast-width: 310px;
  --toastify-toast-min-height: 50px;
  --toastify-font-family: 'Rubik', sans-serif;
  --toastify-text-color-light: #000;
}
