.group {
}

.title {
  display: inline-grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  color: rgb(128, 112, 143);
  font-size: 13px;
  margin: 0px;
  font-weight: 500;
  line-height: 16px;
}

.text {
  font-size: 16px;
  padding: 4px 0px;
  font-weight: 500;
}
