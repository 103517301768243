.stats {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  margin: 5px 0;

  &Total,
  &Success {
    &:after {
      display: inline-block;
      vertical-align: top;
      content: '/';
      margin: 0 3px;
    }
  }

  &Success {
    color: green;
  }

  &Failure {
    color: red;
  }
}
