.searchable {
  position: relative;
  padding-right: 10px;
}

.input {
  width: 250px;

  .active & {
    width: 350px;
  }
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  width: 350px;
  margin-top: 3px;

  .item:first-child {
    border-top: #f7f7f7 1px solid;
  }
}

.item {
  cursor: pointer;
  margin: 0 -24px;
  padding: 5px 24px 2px;
  border-bottom: #f7f7f7 1px solid;

  &:hover {
    background-color: #f7f7f7;
  }
}

@media screen and (max-width: 1024px) {
  .searchable {
    &.active {
      display: grid;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 60px;
      z-index: 9999;
      padding: 0 20px 0 65px;

      .input {
        width: 100%;
      }
    }
  }

  .input {
    width: 100%;
    background-color: #000;
    color: #fff;
    border-color: transparent;
  }

  .dropdown {
    width: 100%;
    margin: 0;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 0;
  }
}
