.autocomplete {
  position: relative;
  padding-right: 10px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  width: 100%;
  margin-top: 3px;
  max-height: 300px;
  overflow: scroll;

  .item:first-child {
    border-top: #f7f7f7 1px solid;
  }
}

.item {
  cursor: pointer;
  margin: 0 -24px;
  padding: 5px 24px 2px;
  border-bottom: #f7f7f7 1px solid;
  font-size: 13px;

  &:hover {
    background-color: #f7f7f7;
  }
}
