.scrollbar {
  padding-top: 16px;
  height: calc(100% - 100px);
  overflow: auto;
}

.navItem,
.navGroup {
  background-color: #10141c;

  &:hover,
  &.active {
    background-color: #222730 !important;
  }
}
