.wrapper {
  display: flex;
  justify-content: center;
  height: 100%;
  background-image: url(~assets/bg.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #5dc3e8 url(~assets/pattern.png) center bottom no-repeat;
  background-size: contain;
  color: #fff;
  width: 40%;
  flex: 1;
  padding: 40px;
  text-align: center;
  border-radius: 0 0 110px 0;

  p {
    margin: 0;
    font-weight: 500;
  }
}

.device {
  max-height: 450px;
}

.heading {
  margin: 20px auto 20px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
}

.content {
  padding: 24px;
  width: 100%;
  max-width: 400px;
}

.logo {
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .sidebar {
    display: none;
  }

  .container {
    width: 100%;
  }

  .content {
    padding: 40px;
  }
}
