.picture {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
}

.source {
  background-color: transparent !important;
}

.orange {
  background-color: #fbe7de;
}

.yellow {
  background-color: #faeed3;
}

.blue {
  background-color: #e0f1f7;
}

.cyan {
  background-color: #ddf2f2;
}

.grey {
  background-color: #e7e8ea;
}

.violet {
  background-color: #dedef6;
}

.green {
  background-color: #e2f5e8;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sizeS {
  border-radius: 6px;
  width: 20px;
  min-width: 20px;
  height: 20px;
}

.sizeM {
  width: 40px;
  min-width: 40px;
  height: 40px;
}

.sizeL {
  width: 60px;
  min-width: 60px;
  height: 60px;
}

.sizeXL {
  width: 80px;
  min-width: 80px;
  height: 80px;
}

.sizeXXL {
  width: 125px;
  min-width: 125px;
  height: 125px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}
