.avatar {
  display: flex;
  border-radius: 50%;
  margin: 0 auto 20px;
  border: #f7f7f7 2px solid;
}

.icon {
  background-color: #bfbebe;
}
