.buttonset {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.option {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 64px;
  border: #f7f7f7 2px solid;
  border-radius: 12px;
  flex: 1;
  cursor: pointer;

  &.active {
    border-color: #57b7da;
  }
}
